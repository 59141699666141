export default {
  methods: {
    updatedByOthers(updatedBy) {
      if ((updatedBy == 'Cleaner') && !this.isCleaner) {
        return true
      }
      if ((updatedBy == 'Client') && !this.isClient) {
        return true
      }
      if (((updatedBy == 'GlobalAdmin') || (updatedBy == 'ServiceAreaAdmin') || (updatedBy == 'Agent')) && !this.isStaff) {
        return true
      }
      return false
    }
  }
}
